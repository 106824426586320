import { FeedbackMetricContext } from "./../models/metric-context";
import { Injectable } from "@angular/core";
import { PhotoMetricContext } from "./../models";

type MetricsRepositoryData = PhotoMetricContext | FeedbackMetricContext;

@Injectable({
  providedIn: "root"
})
export class MetricsRepository {
  private _data: MetricsRepositoryData;

  get data(): MetricsRepositoryData {
    return this._data;
  }

  set data(value: MetricsRepositoryData) {
    this._data = value;
  }

  constructor() {
    this._data = null;
  }
}
