import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import * as moment from "moment";

import { IProductFeedbackMapping, ProductFeedback, IProductHistoryItem, ProductHistoryItem } from "./../components/product-list/model/product-feedback";
@Injectable()
export class ProductService {
  private BASE_URL = environment.BASE_URL;
  private mappings: IProductFeedbackMapping[];

  constructor(private http: HttpClient) {}

  get feedbackMappings(): IProductFeedbackMapping[] {
    return this.mappings;
  }

  public getFeedbacksMapping(): Observable<IProductFeedbackMapping[]> {
    const url = `${this.BASE_URL}/lists/feedback-mapping`;
    return this.http.get<IProductFeedbackMapping[]>(url).pipe(
      tap(resp => {
        this.mappings = resp;
      })
    );
  }

  public saveFeedback(feedback: ProductFeedback): Observable<IProductFeedbackMapping> {
    const { client_id, product_id, feedback_id, list_type, feedback_date } = feedback;

    const date = moment(feedback_date).format("YYYY-MM-DD");
    const url = `${this.BASE_URL}/lists/feedback/${date}/${client_id}/${product_id}`;
    const params = {
      feedback_id,
      list_type
    };

    return this.http.put<IProductFeedbackMapping>(url, params);
  }

  public getHistory(clientId: string, productId: string, startDate: Date, endDate: Date): Observable<ProductHistoryItem[]> {
    const startDateFormated = moment(startDate).format("YYYY-MM-DD");
    const endDateFormated = moment(endDate).format("YYYY-MM-DD");

    const url = `${this.BASE_URL}/lists/feedbacks/${clientId}/${productId}?startDate=${startDateFormated}&endDate=${endDateFormated}`;

    return this.http.get<IProductHistoryItem[]>(url).pipe(map(resp => resp.map(x => new ProductHistoryItem(x))));
  }
}
