<ngx-spinner name="loadSugestList" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-clip-rotate" size="default">
  <p class="loading-location-title">Carregando dados</p>
</ngx-spinner>

<modal-component class="feedback-modal-component" [modalpanelClass]="'feedback-modal-panel'" [modalContentClass]="'feedback-modal-content'">
  <span title ngClass="title dialog-title" ngClass.xs="h4 font-weight-400 width-80-p">
    <button *ngIf="hasPreviousView" mat-icon-button class="ml-12" (click)="backToView()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Sugestões produtos
  </span>
  <div close-button fxLayout="row" *ngIf="!hasPreviousView" fxLayoutAlign="center start">
    <button mat-icon-button (click)="onCancel()" aria-label="Fechar janela">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div content-body fxLayout="column" fxLayoutAlign="stretch" class="feedback-dialog-content" style="width: 100%">
    <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
      <div>
        <span class="h4" matLine>{{ data.client?.client_id }} - {{ data.client?.client }} </span>
        <p class="m-0 h4">
          {{ data.client?.neighborhood }}
          <span *ngIf="!!data.client?.thoroughfare">, {{ data.client?.thoroughfare }}</span>
          <span *ngIf="!!data.client?.house_number">, {{ data.client?.house_number }}</span>
          <span *ngIf="!!data.client?.city">, {{ data.client?.city }}</span>
          <span *ngIf="!!data.client?.phone">, Fone: {{ data.client?.phone }}</span>
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="productListSugestionResponse != undefined && productListSugestionResponse.lists.length > 0; else elseProductListSugestionResponse">
      <mat-tab-group mat-align-tabs="center" (selectedTabChange)="trackClickTab($event)">
        <mat-tab *ngFor="let itemList of productListSugestionResponse.lists">
          <ng-template mat-tab-label>
            <div style="display: flex; flex-direction: column">
              {{ itemList.list_name }}
              <div>
                <span *ngIf="itemList.thumbsUpSelected" (click)="selectThumbsUp(itemList)">
                  <span class="mdi mdi-thumbs-up green-500-fg"> </span>
                </span>
                <span *ngIf="!itemList.thumbsUpSelected" (click)="selectThumbsUp(itemList)">
                  <span class="mdi mdi-thumbs-up grey-500-fg"> </span>
                </span>

                <span *ngIf="itemList.thumbsDownSelected" class="ml-8" (click)="selectThumbsDown(itemList)">
                  <span class="mdi mdi-thumbs-down red-400-fg"> </span>
                </span>
                <span *ngIf="!itemList.thumbsDownSelected" class="ml-8" (click)="selectThumbsDown(itemList)">
                  <span class="mdi mdi-thumbs-down grey-500-fg"> </span>
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template matTabContent>
            <div *ngIf="itemList.recommendations != undefined && itemList.recommendations.length > 0; else elseRecommendationsMoreSales">
              <div *ngFor="let recomendation of itemList.recommendations">
                <product-item [recommendation]="recomendation" [client]="data.client" [listName]="itemList.list_name" (actionClick)="productItemClicked($event)"> </product-item>
              </div>
            </div>
            <ng-template #elseRecommendationsMoreSales>
              <span>Não existem sugestões de produto deste cliente para este tipo de lista</span>
            </ng-template>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <ng-template #elseProductListSugestionResponse>
      <span>Não existem lista sugestões de produto deste cliente</span>
    </ng-template>
  </div>
</modal-component>
