import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { NotificationData } from "app/main/shared/models";
import * as moment from "moment";

@Component({
  selector: "app-reminder-delay",
  templateUrl: "./reminder-delay.component.html",
  styleUrls: ["./reminder-delay.component.scss"]
})
export class ReminderDelayComponent {
  public maxInputValue = 60;
  public inputValue = 5;
  public timeUnit: "minutes" | "hours" = "minutes";
  constructor(@Inject(MAT_DIALOG_DATA) public data: NotificationData, public dialogRef: MatDialogRef<ReminderDelayComponent>) {}

  public onCancel(): void {
    this.dialogRef.close();
  }

  public apply(): void {
    this.data.date = moment().add(this.inputValue, this.timeUnit).toDate();
    this.dialogRef.close(this.data);
  }

  public changeUnit(event: MatSelectChange): void {
    if (event.value === "hours" && this.inputValue > 23) {
      this.maxInputValue = 23;
      this.inputValue = 1;
    } else this.maxInputValue = 60;
  }

  public checkInputLimit(): void {
    if (this.inputValue > this.maxInputValue) this.inputValue = this.maxInputValue;
  }
}
