import { Injectable } from "@angular/core";
import { DatabaseManager } from "./database-manager";
import { SentryErrorHandler } from "./sentry-error-handler.service";
import { Utils } from "./utils";
import { timer, BehaviorSubject } from "rxjs";

@Injectable()
export class StoragesManagementService {
  public readonly storageExists: boolean;
  public isPersistent: boolean;
  public readonly canEstimate: boolean;
  public availbleStorage: boolean;
  public percentUsed: number;
  public usage: number;
  public totalSpace: number;
  public storageInfoWatch: BehaviorSubject<void>;

  constructor(private dataManager: DatabaseManager, private sentryService: SentryErrorHandler) {
    this.storageInfoWatch = new BehaviorSubject<void>(null);
    this.storageExists = !!navigator.storage;
    this.canEstimate = !!(navigator.storage && navigator.storage.estimate);

    this.persistentCheck();

    const time = timer(0, 1000).subscribe(() => {
      this.updateStorageInfo()
        .then(() => this.storageInfoWatch.next())
        .catch(error => {
          time.unsubscribe();
        });
    });
  }

  private persistentCheck(): void {
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then(persistent => {
        if (persistent) this.isPersistent = true;
        else this.isPersistent = false;
      });
    } else {
      this.isPersistent = false;
    }
  }

  public getUsageFormated(): string {
    if (!this.usage) return "0 b";

    const formated = Utils.bytesForHuman(this.usage);
    return `${Math.round(formated.value)} ${formated.unit}`;
  }

  public getTotalSpaceFormated(): string {
    if (!this.totalSpace) return "0 b";

    const formated = Utils.bytesForHuman(this.totalSpace);
    return `${Math.round(formated.value)} ${formated.unit}`;
  }

  public updateStorageInfo(): Promise<void> {
    if (this.storageExists) {
      return navigator.storage.estimate().then(estimate => {
        if (estimate) {
          this.availbleStorage = true;
          this.percentUsed = Math.round((estimate.usage / estimate.quota) * 100);
          this.usage = estimate.usage;
          this.totalSpace = estimate.quota;
        } else {
          this.availbleStorage = false;
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        this.availbleStorage = false;
        reject();
      });
    }
  }

  public clearStorage(): Promise<void> {
    return new Promise((resolve, reject) => {
      const DBDeleteRequest = this.dataManager.clearStorage();
      if (DBDeleteRequest) {
        DBDeleteRequest.onerror = event => {
          reject(new Error("Erro ao limpar o armazenamento local"));
          this.sentryService.handleError(event);
        };

        DBDeleteRequest.onsuccess = event => {
          if (!caches) {
            resolve();
            return;
          }
          caches.keys().then(keys => {
            if (keys.length > 0) {
              keys.map(key => {
                if (key.indexOf("runtime") > 0) {
                  caches
                    .delete(key)
                    .then(() => {
                      resolve();
                    })
                    .catch(error => {
                      reject(new Error("Erro ao limpar o cache"));
                      this.sentryService.handleError(error);
                    });
                }
              });
            } else resolve();
          });
        };
      }
    });
  }
}
