import { EventEmitter } from "@angular/core";

export class EventEmitterService {
  //A
  public static ACADEMY_GOVENDAS = "academygoVendas";

  // C
  public static CHANGE_WORK_MODE_EVENT = "changeWorkMode";

  // F
  public static FINISH_REFRESH_MAP_EVENT = "finishRefreshMap";

  // I
  public static INSERT_PHOTO_LOCAL_EVENT = "insertPhotoLocal";

  // L
  public static LOGIN_EVENT = "login";
  public static LOGOUT_EVENT = "logout";

  // R
  public static REFRESH_EVENT = "refresh";

  // S
  public static SYNC_ALL_EVENT = "syncAll";
  public static SYNC_ITEMS_AFTER_CHANGE_WORK_MODE_EVENT = "syncItemsAfterChangeWorkMode";

  // T
  public static TOGGLE_MENU_LISTENER_EVENT = "toggleMenuListener";
  public static TOGGLE_MAIN_MENU_LISTENER_EVENT = "toggleMainMenuListener";

  // O
  public static OPEN_MODAL_OFFLINE_TOGGLE_EVENT = "openModalOfflineToggLe";
  public static OPEN_FEEDBACK_FROM_REMINDER_EVENT = "openFeedbackFromReminder";

  // U
  public static UPDATE_MAP_CLUSTERS_EVENT = "updateMapClusters";
  public static UPLOAD_PHOTO_EVENT = "uploadPhoto";
  public static UPDATE_OFFLINE_ICON = "updateOfflineIcon";

  // W
  public static WATCH_POSITION_EVENT = "watchPosition";

  private static emitters: {
    [eventName: string]: EventEmitter<any>;
  } = {};

  public static get<T = any>(eventName: string): EventEmitter<T> {
    if (!this.emitters[eventName]) this.emitters[eventName] = new EventEmitter<T>();
    return this.emitters[eventName];
  }
}
