import * as moment from "moment";
export interface IPushNotification {
  heading: string;
  content: string;
  data: string;
}

export class PushNotification implements IPushNotification {
  public heading: string;
  public content: string;
  public data: string;
  public time: string;
  public pushNotificationData: PushNotificationData;

  constructor(source?: IPushNotification) {
    if (source) {
      if (source.heading != null) this.heading = source.heading;
      if (source.content != null) this.content = source.content;
      if (source.data != null) this.data = source.data;
      this.pushNotificationData = new PushNotificationData();
    }
  }

  public tableInfoIsEmpty(): boolean {
    return !(this.pushNotificationData && this.pushNotificationData.table_info && this.pushNotificationData.table_info.length > 0);
  }

  public msgInfoIsEmpty(): boolean {
    return !(this.pushNotificationData && this.pushNotificationData.msg_info && this.pushNotificationData.msg_info.length > 0);
  }

  public tableColumnsIsEmpty(): boolean {
    return !(this.pushNotificationData && this.pushNotificationData.table_columns && this.pushNotificationData.table_columns.length > 0);
  }
}

export interface IPushNotificationData {
  msg_info: string[];
  table_columns: string[];
  table_info: { [key: string]: number | string }[];
}

export class PushNotificationData {
  public msg_info: string[];
  public table_columns: string[];
  public table_info: { [key: string]: number | string }[];

  constructor(source?: IPushNotificationData) {
    if (source) {
      if (source.msg_info != null) this.msg_info = source.msg_info;
      if (source.table_columns != null) this.table_columns = [...new Set(source.table_columns)];
      if (source.table_info != null) this.table_info = source.table_info;
    }
  }
}

export interface INotificationEvent {
  account_id: number;
  already: boolean;
  details: IPushNotificationData;
  id: number;
  inserted_at: Date;
  name: string;
  owner_name: string;
  tags: string[];
  type: string;
  user_id: number;
  validity: number;
  read: boolean;
}

export class NotificationEvent implements INotificationEvent {
  public account_id: number;
  public already: boolean;
  public details: IPushNotificationData;
  public id: number;
  public inserted_at: Date;
  public name: string;
  public owner_name: string;
  public tags: string[];
  public type: string;
  public user_id: number;
  public validity: number;
  public read: boolean;

  constructor(source?: INotificationEvent) {
    if (source.account_id != null) this.account_id = source.account_id;
    if (source.already != null) this.already = source.already;
    if (source.details != null) this.details = source.details;
    if (source.id != null) this.id = source.id;
    if (source.inserted_at != null) this.inserted_at = source.inserted_at;
    if (source.name != null) this.name = source.name;
    if (source.owner_name != null) this.owner_name = source.owner_name;
    if (source.tags != null) this.tags = source.tags;
    if (source.type != null) this.type = source.type;
    if (source.user_id != null) this.user_id = source.user_id;
    if (source.validity != null) this.validity = source.validity;
    if (source.read != null) this.read = source.read;
  }

  get untilNow(): string {
    const now = moment();
    const insertedAt = moment(this.inserted_at);
    let time = "";
    let diff = now.diff(insertedAt, "minutes");
    if (diff < 60)
      if (diff < 1) time = `menos de um minuto`;
      else time = diff === 1 ? `${diff} minuto` : `${diff} minutos`;
    else {
      diff = now.diff(insertedAt, "hours");
      if (diff < 24) time = diff === 1 ? `${diff} hora` : `${diff} horas`;
      else return insertedAt.format("DD/MM/YY HH:mm");
    }

    return `Há ${time}`;
  }
}
