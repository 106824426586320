import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { ToastrService } from "ngx-toastr";
import { interval } from "rxjs";
import { take } from "rxjs/operators";
import { OnlineService } from "./online.service";

@Injectable()
export class UpdateService {
  constructor(
    public updates: SwUpdate,
    public toastr: ToastrService,
    private onlineService: OnlineService
  ) {
    if (updates.isEnabled) {
      interval(1 * 60 * 60 * 1000).subscribe(() => {
        if (this.onlineService.onlineSnapshot) {
          updates.checkForUpdate().then(() => console.log("checking for updates"));
        }
      });
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(() => this.showUpdateMessage());
  }

  private showUpdateMessage(): void {
    console.log("updating to new version");

    this.updates.activateUpdate().then(() => {
      this.toastr
        .info("Clique aqui para atualizar", "NOVA ATUALIZAÇÃO DO GOVENDAS", {
          disableTimeOut: true,
          closeButton: true,
          positionClass: "toast-bottom-center"
        })
        .onTap.pipe(take(1))
        .subscribe(() => {
          window.location.reload();
        });
    });
  }
}
