import { ClientInfo, IClientWithParams, Client } from "./client";
import { User, UserAccount } from "app/main/shared/models";

export class ParamsForm {
  public info: ClientInfo;
  public freq: number;
  public freqLabel: string;
  public prefs: PrefDataForm;
}

export class PrefDataForm {
  public desc?: { label: string; value: number };
  public mon_pref: Pref;
  public tue_pref: Pref;
  public wed_pref: Pref;
  public thu_pref: Pref;
  public fri_pref: Pref;
  public sat_pref: Pref;
  public sun_pref: Pref;
}

export class Pref {
  public value: boolean;
  public isUndefined: boolean;
}

export class ParamsFormData {
  public ids: string[];
  public sellerUser: User;
  public hasPreviousView: boolean;
}

export class SellerParamsFormData {
  public seller: User;
}

export class AccountParamsFormData {
  public account: UserAccount;
}

export class ProductListFormData {
  public client: Client;
  public hasPreviousView: boolean;
}
