<div class="reminder-toast-container" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="reminder-toaster-content-wrapper">
    <span class="mdi mdi-bell reminder-toaster-content-icon" style="font-size: 18px"> </span>
    <div>
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [innerHTML]="message"></div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message" [ngClass]="{ 'mt-4': !!title }">
        {{ message }}
      </div>
    </div>
  </div>
  <mat-icon *ngIf="options.closeButton" class="reminder-toast-close" (click)="remove()">close</mat-icon>
</div>
<div class="reminder-toast-buttons">
  <button mat-stroked-button (click)="action($event, 'delay')">Adiar</button>
  <button mat-stroked-button (click)="action($event, 'action')">{{ okButtonLabel }}</button>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
