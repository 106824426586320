import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ViewService, TrackingService, SentryErrorHandler, AuthService } from "app/main/shared/services";
import { ProductListFormData, User } from "app/main/shared/models";
import { NgxSpinnerService } from "ngx-spinner";
import { SugestService } from "app/main/shared/services/sugest.service";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { ProductListSugestion } from "./model/product-list-sugestion";
import { ListRecommendation } from "./model/list-recommendation";
import { ProductService } from "./../../services/product.service";
import { TimelineProductComponent } from "./../timeline-product/timeline-product.component";
import { ContainerItem } from "./../../models/container";
import { Recommendation } from "./model/recommentation";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"]
})
export class ProductListComponent implements OnInit {
  @Input()
  public data: ProductListFormData;
  public hasPreviousView: boolean;
  public productListSugestionResponse: ProductListSugestion;
  public dateTimeInitTab = new Date();
  private currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<ProductListComponent>,
    public viewService: ViewService,
    private spinner: NgxSpinnerService,
    private trackingService: TrackingService,
    private sentryService: SentryErrorHandler,
    private sugestService: SugestService,
    private toastr: ToastrService,
    private auth: AuthService,
    private productService: ProductService
  ) {
    this.currentUser = this.auth.currentUser;
  }

  public ngOnInit(): void {
    this.hasPreviousView = this.data.hasPreviousView;
    this.loadSugestList();
  }

  public onCancel(data?: any): void {
    this.dialogRef.close(data);
  }

  public backToView(): void {
    this.viewService.removeContent();
  }

  public trackClickTab(event): void {
    const dateTimeAlterTab = new Date();

    const data = {
      list_name: this.productListSugestionResponse.lists[event.index].list_name,
      timer_on_tab_milisecounds: dateTimeAlterTab.getTime() - this.dateTimeInitTab.getTime(),
      client_id: this.data.client.client_id
    };

    this.trackingService.track("alterado aba product_list", data);

    this.dateTimeInitTab = new Date();
  }

  public loadSugestList(): void {
    this.spinner.show("loadSugestList");
    if (!(this.data.client && this.data.client.client_id)) {
      this.spinner.hide("loadSugestList");
      this.toastr.info("Você está offline, não pode fazer logout.");
    } else {
      forkJoin({
        feedbackMappings: this.productService.getFeedbacksMapping(),
        suggestionList: this.sugestService.getListByClientID(this.data.client.client_id)
      }).subscribe(
        resp => {
          this.productListSugestionResponse = resp.suggestionList;

          this.spinner.hide("loadSugestList");
          this.dateTimeInitTab = new Date();
        },
        error => {
          this.spinner.hide("loadSugestList");
          this.sentryService.handleError(error);
        }
      );
    }
  }

  public selectThumbsUp(item: ListRecommendation): void {
    if (item.thumbsUpSelected) return;

    item.thumbsUpSelected = true;
    item.thumbsDownSelected = false;

    const feedback = item.thumbsUpSelected ? "thumbsUp" : "";
    this.trackFeedback(item, feedback);
  }

  public selectThumbsDown(item: ListRecommendation): void {
    if (item.thumbsDownSelected) return;

    item.thumbsDownSelected = true;
    item.thumbsUpSelected = false;

    const feedback = item.thumbsDownSelected ? "thumbsDown" : "";
    this.trackFeedback(item, feedback);
  }

  private trackFeedback(item: ListRecommendation, feedback: string): void {
    const data = {
      salesman_id: this.currentUser.salesman_id,
      salesman: this.currentUser.name,
      feedback,
      list_name: item.list_name
    };
    if (this.data.client) {
      data["client"] = this.data.client.client_id;
      data["client_id"] = this.data.client.client;
    }
    this.trackingService.track("feedback da lista de produtos", data);
  }

  public productItemClicked(recommendation: Recommendation): void {
    const data = {
      client: this.data.client,
      productId: recommendation.product_id,
      productName: recommendation.product_name,
      user: this.currentUser
    };

    const productListContainer = new ContainerItem(TimelineProductComponent, data);
    this.viewService.addContent(productListContainer);
  }
}
