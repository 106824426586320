<div [ngClass]="panelClass" fxLayout="column">
  <div class="modal-title-class" fxLayout="row" fxLayoutAlign="space-between center"
    style="color: white !important; font-size: 16px;" #modalTitleDiv>
    <div class="label-title">
      <ng-content select="[title]">
      </ng-content>
    </div>
    <ng-content select="[close-button]"> </ng-content>
  </div>
  <div style="background-color: white; box-shadow: 0 4px 5px -5px black;" #modalTopFixedDiv>
    <ng-content select="[content-header]"> </ng-content>
  </div>
  <div [ngClass]="contentClass" #contentBody>
    <ng-content select="[content-body]"> </ng-content>
  </div>
  <div *ngIf="!hideActionsDiv" class="modal-actions-class" fxLayout="row" fxLayoutAlign="end end" #modalActionsDiv>
    <ng-content select="[content-cancel]"> </ng-content>
    <ng-content select="[content-ok]"> </ng-content>
  </div>
</div>
