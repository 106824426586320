import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { User, UserAccount } from "app/main/shared/models";
import { environment } from "environments/environment";

@Injectable()
export class SentryErrorHandler {
  private userContext: User;

  constructor() {}
  public handleError(error, extra?: any): void {
    if (!isDevMode()) {
      if (error instanceof HttpErrorResponse) {
        this.handleHttpError(error, extra);
      } else {
        this.sendError(error, extra);
      }

      console.error(error);
    } else {
      console.error(error);
    }
  }

  private sendError(error, extra?: any): void {
    if (extra) {
      Sentry.captureException(error.originalError || error, {
        extra
      });
    } else {
      Sentry.captureException(error.originalError || error);
    }
  }

  public handleMessage(msg: string): void {
    if (!isDevMode()) {
      Sentry.captureMessage(msg);
    } else {
      console.warn(msg);
    }
  }

  public setUserInfo(user: User, account: UserAccount): void {
    this.userContext = user;

    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.id.toString(),
        email: user.email,
        username: user.name,
        accountId: user.account_id,
        role: user.role,
        salesmanId: user.salesman_id
      });

      scope.setExtras({
        appVersion: environment.app_version,
        appInstalled: window.matchMedia("(display-mode: standalone)").matches
      });

      scope.setTag("account_name", account.name);
    });
  }

  private handleHttpError(err: HttpErrorResponse, extra: any = {}): void {
    const requestId = err.headers.get(environment.requestIdHeader);
    if (requestId) {
      const extraData = extra ? extra : {};
      extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
      this.sendError(err, extraData);
    } else {
      this.sendError(err);
    }
  }
}
