import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ISellerInfo } from "../models";
import { Rule, RuleForService } from "../models/rules";

@Injectable()
export class RulesService {
  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  public getRuptureRulesMapping(): any {
    const url = `${this.BASE_URL}/rupture-rules`;
    return this.http.get<Rule>(url).pipe(map(res => res[0]));
  }

  public getSellers(ids?: string[]): Observable<ISellerInfo[]> {
    const url = `${this.BASE_URL}/users/salesmans`;
    const body = ids ? ids : [];
    return this.http.post<ISellerInfo[]>(url, body);
  }

  public updateRuptureRule(body: Rule): Observable<Rule> {
    const url = `${this.BASE_URL}/rules/${body.id}`;
    const newBody = new RuleForService(body);
    return this.http.put<Rule>(url, newBody);
  }

  public disableRule(body: Rule): Observable<Rule> {
    const url = `${this.BASE_URL}/rules/${body.id}`;
    body.enabled = false;
    const newBody = new RuleForService(body);
    return this.http.put<Rule>(url, newBody);
  }

  public enableRule(body: Rule): Observable<Rule> {
    const url = `${this.BASE_URL}/rules/${body.id}`;
    body.enabled = true;
    const newBody = new RuleForService(body);
    return this.http.put<Rule>(url, newBody);
  }

  public createNewRuptureRule(body: Rule): Observable<Rule> {
    const url = `${this.BASE_URL}/rules/${body.id}`;
    const newBody = new RuleForService(body);
    return this.http.put<Rule>(url, newBody);
  }

  public createNewRule(body: Rule): Observable<Rule> {
    const url = `${this.BASE_URL}/rules`;
    const newBody = new RuleForService(body);
    const ruptureRule = this.http.post<any>(url, newBody).pipe(
      map(res => {
        return {
          id: res.id,
          rule_name: res.rule_name,
          type: res.type,
          inserted_at: res.inserted_at,
          updated_at: res.updated_at,
          ruptureRule: JSON.parse(res.rule)
        } as Rule;
      })
    );

    return ruptureRule;
  }
}
