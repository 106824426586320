import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DatabaseManager } from "./database-manager";
import * as moment from "moment";
import { NotificationData, TrackingEvents, User } from "../models";
import { NotificationsRepository } from "../repositories/notifications.repository";
import { ActiveToast, GlobalConfig, ToastrService } from "ngx-toastr";
import { AuthService } from "./auth.service";
import { ReminderToastrComponent } from "../components/alert-notifications/components/reminder-toastr/reminder-toastr.component";
import { EventEmitterService } from "./event.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ReminderDelayComponent } from "../components/alert-notifications/components/reminder-delay/reminder-delay.component";
import { TrackingService } from "./tracking.service";
import { Feedback } from "./../models/feedback";

@Injectable()
export class NotificationSevice {
  public itemsCollection: any;
  public items: any;
  private currentUser: User;

  private _notificationsList: NotificationData[];

  private options: GlobalConfig;

  constructor(
    public http: HttpClient,
    public dbManager: DatabaseManager,
    public notificationsRepository: NotificationsRepository,

    private auth: AuthService,
    private toastr: ToastrService,
    private _router: Router,
    private dialog: MatDialog,
    private trackingService: TrackingService
  ) {
    this.auth.getUser().subscribe(user => {
      this.currentUser = user;
      this.getNotifications(user.account_id, user.id);
    });

    this._notificationsList = [];

    this.options = this.toastr.toastrConfig;
  }

  public showMessage(reminder: NotificationData): void {
    const opt = { ...this.options };
    opt.toastComponent = ReminderToastrComponent;
    opt.toastClass = "reminder-toast";
    opt.disableTimeOut = true;
    opt.tapToDismiss = false;
    opt.positionClass = "toast-top-center";
    opt.closeButton = true;
    const toastrActive: ActiveToast<ReminderToastrComponent> = this.toastr.show(reminder.text, "Lembrete", opt);
    const componentInstance = toastrActive.portal.instance;
    if (!reminder.isGeneral) componentInstance.okButtonLabel = "Registrar atendimento";

    toastrActive.onAction.subscribe(source => {
      if (source === "action") {
        if (!reminder.isGeneral) {
          if (!(this._router.url === "/atendimento")) {
            this._router.navigate(["/atendimento/lembrete/" + reminder.id]);
          } else {
            EventEmitterService.get(EventEmitterService.OPEN_FEEDBACK_FROM_REMINDER_EVENT).emit(reminder);
          }

          let clientData;

          try {
            clientData = JSON.parse(reminder.data);
          } catch (error) {}

          const trackingDaqta: any = {
            source: "toastr",
            client_id: clientData ? clientData.client_id : ""
          };

          this.trackingService.track(TrackingEvents.REMINDER_OPENED, trackingDaqta);
        }

        reminder.isRead = true;
        this.updateNotifications([reminder]);
        componentInstance.remove();
      } else {
        const dialogRef = this.dialog.open(ReminderDelayComponent, {
          minWidth: "300px",
          maxWidth: "100vw",
          maxHeight: "250px",
          height: "50%",
          data: reminder
        });

        dialogRef.afterClosed().subscribe((data: NotificationData) => {
          if (data) {
            this.updateNotifications([data]);
            componentInstance.remove();
          }
        });
      }
    });
  }

  public get allNotifications(): NotificationData[] {
    return this.notificationsRepository.allNotificationsList;
  }

  set setNotificationsList(val: NotificationData[]) {
    const unread = this._notificationsList.filter(item => item.isRead === false);
    const dataUnread = val.filter(item => item.isRead === false);

    if (dataUnread && unread.length !== dataUnread.length && dataUnread.length > unread.length) {
      const audio = new Audio("assets/sounds/notification_alert.wav");
      audio.play();

      const newRemind = dataUnread[0];
      this.showMessage(newRemind);

      const data: any = {
        isGeneral: newRemind.isGeneral
      };
      if (!newRemind.isGeneral) {
        try {
          const reminderData: Feedback = JSON.parse(newRemind.data);
          data.client_id = reminderData.client_id;
        } catch {}
      }

      this.trackingService.track(TrackingEvents.REMINDER_TRIGGED, data);
    }

    this._notificationsList = val;
  }

  public get notificationsList(): NotificationData[] {
    this.setNotificationsList = this.allNotifications.filter(item => {
      return moment(item.date).isBefore(moment()) && item.userId === this.currentUser.id;
    });

    return this._notificationsList;
  }

  public async getNotifications(account_id: number, user_id: number): Promise<NotificationData[]> {
    const range = {
      max: [account_id, user_id, moment(new Date()).add(30, "days").toDate()]
    };
    const data = await this.dbManager.getAll(DatabaseManager.NOTIFICATION_STORE_NAME, range, "notificationQuery");

    this.notificationsRepository.save(data);
    return this.allNotifications;
  }

  public getNotificationByID(id: number): Promise<NotificationData> {
    return this.dbManager.getByKey(DatabaseManager.NOTIFICATION_STORE_NAME, id);
  }

  public async updateNotifications(notifications: NotificationData[]): Promise<NotificationData[]> {
    for (const item of notifications) {
      await this.dbManager.updateItem(DatabaseManager.NOTIFICATION_STORE_NAME, item);
    }
    this.getNotifications(this.currentUser.account_id, this.currentUser.id);
    return notifications;
  }

  public async addNotification(notification: NotificationData): Promise<NotificationData> {
    await this.dbManager.addItem(DatabaseManager.NOTIFICATION_STORE_NAME, notification);
    this.getNotifications(this.currentUser.account_id, this.currentUser.id);
    return notification;
  }

  public async removeNotifications(account_id: number, user_id: number): Promise<NotificationData[]> {
    const range = {
      max: moment(new Date()).subtract(1, "day").endOf("day").toDate()
    };

    const callback = (evt: Event) => {
      const cursor = (<IDBRequest>evt.target).result;
      if (cursor) {
        const { value } = cursor;
        if (value.account_id === account_id && value.user_id === user_id) cursor.delete();

        cursor.continue();
      }
    };

    await this.dbManager.openCursorByRange(DatabaseManager.NOTIFICATION_STORE_NAME, callback, range, "date");

    this.notificationsRepository.save(await this.getNotifications(account_id, user_id));
    return this.notificationsRepository.allNotificationsList;
  }

  public async removeNotificationById(notification: NotificationData): Promise<NotificationData> {
    await this.dbManager.removeById(DatabaseManager.NOTIFICATION_STORE_NAME, notification.id);

    this.getNotifications(this.currentUser.account_id, this.currentUser.id);
    return notification;
  }
}
