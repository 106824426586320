import { ICountClients } from "./countPhotos";
import { StatisticsCustomerWallet } from "./statisticsCustomerWallet";
import { MetaParameters } from "./meta-paramters";

type UserRole = "SUPER" | "ADMIN" | "MANAGER" | "SUPERVISOR" | "SALESMAN";

export class UserAccount {
  public email: string;
  public gogeo: GogeoAccountInfo;
  public id: number;
  public inactive_days: number;
  public name: string;
  public purchase_date: Date;
  public skip_scheduling: boolean;
  public meta_parameters: MetaParameters;
  public update_at: Date;
  public updated_by: number;
}

export class GogeoAccountInfo {
  public apikey: string;
  public gogeo_email: string;
  public gogeo_user_id: number;
  public key: string;
  public mapkey: string;
}

export interface IUser {
  id?: number;
  email?: string;
  password?: string;
  token?: string;
  salesman_id?: string;
  role?: UserRole;
  users?: User[];
  name: string;
  account_id: number;
  phone: string;
  salesman_ids: string[];
  salesman_type: "EXTERNAL" | "INTERNAL";
  skip_scheduling: boolean;
  ignore_blocked: boolean;
  active: boolean;
  inactive_days: number;
  max_visit: number;
  notification_enabled: boolean;
  lat_start: number;
  lon_start: number;
  lat_end: number;
  lon_end: number;
  isAssociate?: boolean;
  access_all_clients?: boolean;
  allow_change_params?: boolean;
  allow_incremental_observations?: boolean;
  statistics?: StatisticsCustomerWallet;
  meta_parameters: MetaParameters;
  list_permission: string[];
  group: number;
  events: string[];
  can_update_rupture_rule: boolean;
}
export class BatchCreatedUser {
  public user: User;
  public created: boolean;
  public alreadyExists: boolean;
  public error: boolean;
  public message?: string;
}

export class UserEnabledResponse {
  public error: boolean;
  public message?: string;
  public user: User[];
}

export class User implements IUser {
  public id?: number;
  public email?: string;
  public password?: string;
  public token?: string;
  public salesman_id?: string;
  public role?: "SUPER" | "ADMIN" | "MANAGER" | "SUPERVISOR" | "SALESMAN";
  public users?: User[];
  public name = "";
  public account_id: number;
  public phone: string;
  public salesman_ids: string[];
  public salesman_type: "EXTERNAL" | "INTERNAL";
  public skip_scheduling: boolean;
  public ignore_blocked: boolean;
  public inactive_days: number;
  public active: boolean;
  public max_visit: number;
  public notification_enabled: boolean;
  public lat_start: number;
  public lon_start: number;
  public lat_end: number;
  public lon_end: number;
  public isAssociate?: boolean;
  public access_all_clients?: boolean;
  public allow_change_params?: boolean;
  public allow_incremental_observations?: boolean;
  public statistics?: StatisticsCustomerWallet;
  public meta_parameters: MetaParameters;
  public list_permission: string[] = [];
  public group: number;
  public events: string[];
  public userToUpdateOnDatabase?: "NO" | "YES";
  public can_update_rupture_rule: boolean;

  constructor(user?: IUser) {
    this.copyFrom(user);
  }

  public copyFrom(user: IUser): void {
    if (user) {
      this.id = user.id != null ? user.id : this.id;
      this.email = user.email != null ? user.email : this.email;
      this.password = user.password != null ? user.password : this.password;
      this.token = user.token != null ? user.token : this.token;
      this.salesman_id = user.salesman_id != null ? user.salesman_id : this.salesman_id;
      this.role = user.role != null ? user.role : this.role;
      this.users = user.users != null ? user.users.map(x => new User(x)) : this.users;
      this.name = user.name != null ? user.name : this.name;
      this.account_id = user.account_id != null ? user.account_id : this.account_id;
      this.phone = user.phone != null ? user.phone : this.phone;
      this.salesman_ids = user.salesman_ids != null ? user.salesman_ids : this.salesman_ids;
      this.salesman_type = user.salesman_type != null ? user.salesman_type : this.salesman_type;
      this.skip_scheduling = user.skip_scheduling != null ? user.skip_scheduling : this.skip_scheduling;
      this.ignore_blocked = user.ignore_blocked != null ? user.ignore_blocked : this.ignore_blocked;
      this.inactive_days = user.inactive_days != null ? user.inactive_days : this.inactive_days;
      this.active = user.active != null ? user.active : this.active;
      this.max_visit = user.max_visit != null ? user.max_visit : this.max_visit;
      this.notification_enabled = user.notification_enabled != null ? user.notification_enabled : this.notification_enabled;
      this.lat_start = user.lat_start != null ? user.lat_start : this.lat_start;
      this.lon_start = user.lon_start != null ? user.lon_start : this.lon_start;
      this.lat_end = user.lat_end != null ? user.lat_end : this.lat_end;
      this.lon_end = user.lon_end != null ? user.lon_end : this.lon_end;
      this.isAssociate = user.isAssociate != null ? user.isAssociate : this.isAssociate;
      this.access_all_clients = user.access_all_clients != null ? user.access_all_clients : this.access_all_clients;
      this.allow_change_params = user.allow_change_params != null ? user.allow_change_params : this.allow_change_params;
      this.active = user.active != null ? user.active : this.active;
      this.allow_incremental_observations =
        user.allow_incremental_observations != null ? user.allow_incremental_observations : this.allow_incremental_observations;
      this.meta_parameters = user.meta_parameters != null ? new MetaParameters(user.meta_parameters) : new MetaParameters(this.meta_parameters);
      this.list_permission = user.list_permission != null ? user.list_permission : [];
      this.group = user.group != null ? user.group : 0;
      this.events = user.events != null ? user.events : [];
      this.can_update_rupture_rule = user.can_update_rupture_rule != null ? user.can_update_rupture_rule : this.can_update_rupture_rule;
    }
  }

  public addStatiscs(source: ICountClients): void {
    if (source) {
      if (!this.statistics) this.statistics = new StatisticsCustomerWallet();
    }
  }

  public notificationsEnabled(): boolean {
    return this.events && this.events.length > 0;
  }

  public getManagers(): User[] {
    if (this.role === "MANAGER" || this.users.length === 0) return [];

    return this.getUserByRole(this, "MANAGER");
  }

  public getSupervisors(): User[] {
    if (this.role === "SUPERVISOR" || this.users.length === 0) return [];

    return this.getUserByRole(this, "SUPERVISOR");
  }

  public getSalesmen(): User[] {
    if (this.role === "SALESMAN" || this.users.length === 0) return [];

    return this.getUserByRole(this, "SALESMAN");
  }

  private getUserByRole(user: User, role: UserRole, flag: { [key: string]: boolean } = {}): User[] {
    if (user.role === role) {
      if (flag[user.id]) {
        return [];
      } else {
        flag[user.id] = true;
        return [user];
      }
    }
    if (!user.users || user.users.length === 0) return [];

    return user.users
      .filter(u => u.id !== user.id)
      .map(x => this.getUserByRole(x, role, flag))
      .flat();
  }
}

export interface IUserHierarchy {
  user_id: number;
  related_users: IUser[];
}

export class UserHierarchy implements IUserHierarchy {
  public user_id: number;
  public related_users: User[] = [];

  constructor(source?: IUserHierarchy) {
    if (source) {
      if (source.user_id != null) this.user_id = source.user_id;
      if (source.related_users != null) this.related_users = source.related_users.map(x => new User(x));
    }
  }
}
