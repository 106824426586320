import { Agenda, User } from "app/main/shared/models";
import { IAgenda } from "./agenda";
import { ItemClient } from "./itemClient";
import { Feedback } from "./feedback";

export interface IClient {
  client: string;
  client_id: string;
  cnpj_cpf: string;
  phone: string;
  email: string;
  neighborhood: string;
  thoroughfare: string;
  block: string;
  complement: string;
  city: string;
  state: string;
  itiner_date: Date;
  days_without_sales: number;
  days_without_visits: number;
  rescheduler_date: Date;
  lat: number;
  log: number;
  status_value: string;
  observations: string;
  house_number: string;
  fantasy_name: string;
  cnae1: string;
  cnae1_description: string;
  exclusion: boolean;
  blocked: boolean;
  activity: string;
  activity_id: string;

  dataCollect: any;
  containsDataCollect: boolean;
  dataInLocalStorage: boolean;
  photo_url: string;
  image_infos: ImageInfos[];

  trusted_coordinate: GeoJSON.Feature;
  erp_coordinate: GeoJSON.Point;
  _geom_centroid: GeoJSON.Point;

  defaulting: boolean;
  credit_total: number;
  credit_available: number;
  billing_plan: BillingPlan;
  payment_plan: PaymentPlan;
  erp_phones: Phones[];
  phones: Phones[];
}

export class Client implements IClient {
  public client = "";
  public client_id = "";
  public cnpj_cpf = "";
  public phone = "";
  public email = "";

  public neighborhood = "";
  public thoroughfare = "";
  public block = "";
  public complement = "";
  public city = "";
  public state = "";
  public itiner_date: Date = null;
  public days_without_sales: number = null;
  public days_without_visits: number = null;
  public rescheduler_date: Date = null;
  public lat = 0;
  public log = 0;
  public status_value = "";
  public observations = "";
  public house_number = "";
  public fantasy_name = "";
  public cnae1 = "";
  public cnae1_description = "";
  public activity = "";
  public activity_id = "";
  public exclusion: boolean = null;
  public blocked: boolean = null;

  public dataCollect = new ItemClient();
  public containsDataCollect: boolean = null;
  public dataInLocalStorage: boolean = null;
  public photo_url = "";
  public image_infos: ImageInfos[];

  public trusted_coordinate: GeoJSON.Feature = null;
  public erp_coordinate: GeoJSON.Point = null;
  public _geom_centroid: GeoJSON.Point = null;

  public defaulting: boolean;
  public credit_total = 0;
  public credit_available = 0;
  public billing_plan = new BillingPlan();
  public payment_plan = new PaymentPlan();
  public erp_phones: Phones[] = [];
  public phones: Phones[] = [];

  constructor(source?: IClient) {
    if (source) {
      this.fromClient(source);
    }
  }

  public fromClient(obj: IClient): void {
    if (obj.client != null) this.client = obj.client;
    if (obj.client_id != null) this.client_id = obj.client_id;
    if (obj.cnpj_cpf != null) this.cnpj_cpf = obj.cnpj_cpf;
    if (obj.phones != null) this.phones = obj.phones;
    if (obj.phone != null) this.phone = obj.phone;
    if (obj.email != null) this.email = obj.email;
    if (obj.neighborhood != null) this.neighborhood = obj.neighborhood;
    if (obj.thoroughfare != null) this.thoroughfare = obj.thoroughfare;
    if (obj.block != null) this.block = obj.block;
    if (obj.complement != null) this.complement = obj.complement;
    if (obj.city != null) this.city = obj.city;
    if (obj.state != null) this.state = obj.state;
    if (obj.itiner_date != null) this.itiner_date = obj.itiner_date;
    if (obj.days_without_sales != null) this.days_without_sales = obj.days_without_sales;
    if (obj.days_without_visits != null) this.days_without_visits = obj.days_without_visits;
    if (obj.rescheduler_date != null) this.rescheduler_date = obj.rescheduler_date;
    if (obj.lat != null) this.lat = obj.lat;
    if (obj.log != null) this.log = obj.log;
    if (obj.status_value != null) this.status_value = obj.status_value;
    if (obj.observations != null) this.observations = obj.observations;
    if (obj.house_number != null) this.house_number = obj.house_number;
    if (obj.fantasy_name != null) this.fantasy_name = obj.fantasy_name;
    if (obj.activity != null) this.activity = obj.activity;
    if (obj.activity_id != null) this.activity_id = obj.activity_id;
    if (obj.cnae1 != null) this.cnae1 = obj.cnae1;
    if (obj.cnae1_description != null) this.cnae1_description = obj.cnae1_description;
    if (obj.exclusion != null) this.exclusion = obj.exclusion;
    if (obj.blocked != null) this.blocked = obj.blocked;
    if (obj.image_infos != null) this.image_infos = obj.image_infos;

    if (obj.defaulting != null) this.defaulting = obj.defaulting;
    if (obj.credit_total != null) this.credit_total = obj.credit_total;
    if (obj.credit_available != null) this.credit_available = obj.credit_available;
    if (obj.billing_plan != null) this.billing_plan = obj.billing_plan;
    if (obj.payment_plan != null) this.payment_plan = obj.payment_plan;
    if (obj.erp_phones != null) this.erp_phones = obj.erp_phones;
    if (obj.trusted_coordinate != null) this.trusted_coordinate = obj.trusted_coordinate;
  }

  public isEmpty(): boolean {
    return !this.client_id;
  }

  public fromFeedback(source: Feedback): void {
    if (source.client != null) this.client = source.client;
    if (source.fantasy_name != null) this.fantasy_name = source.fantasy_name;
    if (source.client_id != null) this.client_id = source.client_id;
    if (source.phone != null) this.phone = source.phone;
    if (source.neighborhood != null) this.neighborhood = source.neighborhood;
    if (source.thoroughfare != null) this.thoroughfare = source.thoroughfare;
    if (source.house_number != null) this.house_number = source.house_number;
    if (source.block != null) this.block = source.block;
    if (source.city != null) this.city = source.city;
    if (source.state != null) this.state = source.state;
  }
}

export class ImageInfos {
  public imageInfo: ImageInfo;
  public token: string;
}

export class ImageInfo {
  public owner: number;
  public date: string;
  public coordinate: string;
  public cnpj: string;
  public clientId: string;
  public accuracy: number;
  public missing_file: boolean;
}

export class IClientWithParams {
  public client: ClientInfo;
  public params: ClientParams;
}

export class FormHistoryParamters {
  public userSelected: User;
  public client: ClientInfo;
}
export class ClientWithParams implements IClientWithParams {
  public client = new ClientInfo();
  public params = new ClientParams();

  constructor(clientWithParams?: IClientWithParams) {
    if (clientWithParams) {
      if (clientWithParams.client != null) this.client = new ClientInfo(clientWithParams.client);
      if (clientWithParams.params != null) this.params = new ClientParams(clientWithParams.params);
    }
  }

  public fromAgenda(agenda: Agenda): void {
    this.client = new ClientInfo();
    this.params = new ClientParams();

    this.client.client = agenda.client;
    this.client.client_id = agenda.client_id;
    this.client.fantasy_name = agenda.fantasy_name;

    this.params.fromAgenda(agenda);
  }

  public fromClient(client: Client, clientParams: ClientParams): void {
    this.client = new ClientInfo();
    this.params = new ClientParams();

    this.client.client = client.client;
    this.client.client_id = client.client_id;
    this.client.fantasy_name = client.fantasy_name;

    if (clientParams) {
      this.params = clientParams;
    }
  }
}

export class BillingPlan {
  public id = "";
  public name = "";
}

export class PaymentPlan {
  public id = "";
  public name = "";
}

export class Phones {
  public label: string;
  public value: string;
}

export class ClientInfo {
  public client = "";
  public fantasy_name = "";
  public client_id = "";
  public cnpj_cpf = "";
  public neighborhood = "";
  public thoroughfare = "";
  public house_number = "";
  public block = "";
  public city = "";
  public state = "";
  public lat = 0;
  public log = 0;
  public _geom_centroid: GeoJSON.Point = null;
  public post_code = "";
  public phone = "";
  public image_infos: ImageInfos[] = [];
  public revenue = 0;
  public revenue_range = "";
  public credit_available;
  public credit_total;
  public cnae1_description = "";
  public cnae1 = "";
  public activity = "";
  public activity_id = "";
  public exclusion: boolean = null;
  public trusted_coordinate: GeoJSON.Feature = null;

  constructor(clientInfo?: ClientInfo) {
    if (clientInfo) {
      if (clientInfo.client != null) this.client = clientInfo.client;
      if (clientInfo.fantasy_name != null) this.fantasy_name = clientInfo.fantasy_name;
      if (clientInfo.client_id != null) this.client_id = clientInfo.client_id;
      if (clientInfo.cnpj_cpf != null) this.cnpj_cpf = clientInfo.cnpj_cpf;
      if (clientInfo.neighborhood != null) this.neighborhood = clientInfo.neighborhood;
      if (clientInfo.thoroughfare != null) this.thoroughfare = clientInfo.thoroughfare;
      if (clientInfo.house_number != null) this.house_number = clientInfo.house_number;
      if (clientInfo.block != null) this.block = clientInfo.block;
      if (clientInfo.city != null) this.city = clientInfo.city;
      if (clientInfo.state != null) this.state = clientInfo.state;
      if (clientInfo.lat != null) this.lat = clientInfo.lat;
      if (clientInfo.log != null) this.log = clientInfo.log;
      if (clientInfo.post_code != null) this.post_code = clientInfo.post_code;
      if (clientInfo.phone != null) this.phone = clientInfo.phone;
      if (clientInfo.image_infos != null) this.image_infos = clientInfo.image_infos;
      if (clientInfo.revenue != null) this.revenue = clientInfo.revenue;
      if (clientInfo.revenue_range != null) this.revenue_range = clientInfo.revenue_range;
      if (clientInfo.cnae1 != null) this.cnae1 = clientInfo.cnae1;
      if (clientInfo.cnae1_description != null) this.cnae1_description = clientInfo.cnae1_description;
      if (clientInfo.activity != null) this.activity = clientInfo.activity;
      if (clientInfo.activity_id != null) this.activity_id = clientInfo.activity_id;
      if (clientInfo.credit_available != null) this.credit_available = clientInfo.credit_available;
      if (clientInfo.credit_total != null) this.credit_total = clientInfo.credit_total;
      if (clientInfo.exclusion != null) this.exclusion = clientInfo.exclusion;
      if (clientInfo.trusted_coordinate) this.trusted_coordinate = clientInfo.trusted_coordinate;
    }
  }
}

export interface IClientDayPreferencesParams {
  mon_pref: number;
  tue_pref: number;
  wed_pref: number;
  thu_pref: number;
  fri_pref: number;
  sat_pref: number;
  sun_pref: number;
}

export class ClientDayPreferencesParams implements IClientDayPreferencesParams {
  public mon_pref: number = null;
  public tue_pref: number = null;
  public wed_pref: number = null;
  public thu_pref: number = null;
  public fri_pref: number = null;
  public sat_pref: number = null;
  public sun_pref: number = null;

  constructor(client?: IClientParams) {
    if (client) {
      if (client.mon_pref != null) this.mon_pref = client.mon_pref;
      if (client.tue_pref != null) this.tue_pref = client.tue_pref;
      if (client.wed_pref != null) this.wed_pref = client.wed_pref;
      if (client.thu_pref != null) this.thu_pref = client.thu_pref;
      if (client.fri_pref != null) this.fri_pref = client.fri_pref;
      if (client.sat_pref != null) this.sat_pref = client.sat_pref;
      if (client.sun_pref != null) this.sun_pref = client.sun_pref;
    }
  }

  public fromAgenda(agenda: IAgenda): void {
    this.mon_pref = agenda.mon_pref;
    this.tue_pref = agenda.tue_pref;
    this.wed_pref = agenda.wed_pref;
    this.thu_pref = agenda.thu_pref;
    this.fri_pref = agenda.fri_pref;
    this.sat_pref = agenda.sat_pref;
    this.sun_pref = agenda.sun_pref;
  }
}

export interface IClientParams extends IClientDayPreferencesParams {
  client_id: string;
  date: Date;
  freq: number;
  generated_by: number;
  group: number;
  id: string;
  non_schedulable: boolean;
  salesman_id: string;
  user_id: number;
  username: string;
  observations: string;
  routable: boolean;
  defaulting: boolean;
  salesman: string;
  pivot_week: number;
  fixed: boolean;
}

export class ClientParams extends ClientDayPreferencesParams implements IClientParams {
  public client_id = "";
  public date: Date = null;
  public freq: number = null;
  public generated_by: number = null;
  public group: number = null;
  public id: string = null;
  public non_schedulable: boolean = null;
  public salesman_id: string = null;
  public user_id: number = null;
  public username: string = null;
  public observations = "";
  public routable: boolean;
  public defaulting: boolean = null;
  public salesman: string = null;
  public pivot_week = 0;
  public fixed: boolean;

  constructor(client?: IClientParams) {
    super(client);
    this.fromClientParams(client);
  }

  public fromAgenda(agenda: IAgenda): void {
    super.fromAgenda(agenda);
    this.client_id = agenda.client_id;
    this.salesman_id = agenda.salesmanId;
    this.non_schedulable = agenda.non_schedulable;
    this.freq = agenda.freq;
    this.routable = agenda.routable;
    this.fixed = agenda.fixed;
  }

  public fromClientParams(params: IClientParams): void {
    if (params) {
      if (params.client_id != null) this.client_id = params.client_id;
      if (params.date != null) this.date = params.date;
      if (params.freq != null) this.freq = params.freq;
      if (params.generated_by != null) this.generated_by = params.generated_by;
      if (params.group != null) this.group = params.group;
      if (params.id != null) this.id = params.id;
      if (params.non_schedulable != null) this.non_schedulable = params.non_schedulable;
      if (params.salesman_id != null) this.salesman_id = params.salesman_id;
      if (params.user_id != null) this.user_id = params.user_id;
      if (params.username != null) this.username = params.username;
      if (params.observations != null) this.observations = params.observations;
      if (params.routable != null) this.routable = params.routable;
      if (params.fixed != null) this.fixed = params.fixed;
      if (params.defaulting != null) this.defaulting = params.defaulting;
      if (params.salesman != null) this.salesman = params.salesman;
      if (params.pivot_week != null) this.pivot_week = params.pivot_week;
      if (params.mon_pref != null) this.mon_pref = params.mon_pref;
      if (params.tue_pref != null) this.tue_pref = params.tue_pref;
      if (params.wed_pref != null) this.wed_pref = params.wed_pref;
      if (params.thu_pref != null) this.thu_pref = params.thu_pref;
      if (params.fri_pref != null) this.fri_pref = params.fri_pref;
      if (params.sat_pref != null) this.sat_pref = params.sat_pref;
      if (params.sun_pref != null) this.thu_pref = params.thu_pref;
    }
  }
}
