export class NotificationData {
  public id: number;
  public userId: number;
  public accountId: number;
  public date: Date;
  public text: string;
  public isGeneral: boolean;
  public isRead: boolean;
  public data: any;
}
