import { Injectable } from "@angular/core";
import { WorkBook, WorkSheet } from "xlsx";

@Injectable()
export class ExcelService {
  constructor() {}

  public static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().toLocaleString()}.xlsx`;
  }

  public async exportAsExcelFile(json: any[], excelFileName: string): Promise<void> {
    const XLSX = await import("xlsx");
    const worksheet: WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: WorkBook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName));
  }
}
