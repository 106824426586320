import { TrackingService } from "./tracking.service";
import { filter } from "rxjs/operators";
import { skip } from "rxjs/operators";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { PhotoMetricContext, FeedbackMetricContext } from "./../models/metric-context";
import { MetricsRepository } from "./../repositories/metrics.repository";

@Injectable()
export class LocationService {
  private positionObservable: Subject<GeolocationPosition>;
  private locationRequestsId: number;

  constructor(private trackingService: TrackingService, private metricsRepository: MetricsRepository) {}

  private options = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 0
  };

  public watchPosition(limit?: number): Observable<GeolocationPosition> {
    if (this.positionObservable) {
      this.trackingService.track("reaproveitando coleta de coordenadas");
      return this.positionObservable;
    }

    this.positionObservable = new Subject<GeolocationPosition>();
    let count = 0;
    if (!navigator.geolocation) {
      this.positionObservable.error({ code: -1, message: "Dispositivo sem suporte a GPS" });
      return;
    }
    this.locationRequestsId = navigator.geolocation.watchPosition(
      position => {
        this.positionObservable.next(position);
        count++;
        if (limit && count === limit) {
          this.positionObservable.complete();
          navigator.geolocation.clearWatch(this.locationRequestsId);
        }
      },
      error => {
        this.positionObservable.error(error);
      },
      this.options
    );

    return this.positionObservable.pipe(
      skip(1),
      filter((pos: GeolocationPosition) => {
        if ("coords" in pos) {
          const currentTimestamp = +new Date();
          const diff = currentTimestamp - pos.timestamp;
          if (diff <= 30 * 1000) {
            return true;
          } else {
            const { data } = this.metricsRepository;
            const trackData =
              data instanceof PhotoMetricContext || data instanceof FeedbackMetricContext
                ? {
                    client_id: data.clientId,
                    coords_timestamp: pos.timestamp,
                    current_timestamp: currentTimestamp,
                    diff_timestamp: diff
                  }
                : null;
            this.trackingService.track("coordenada antiga", trackData);
            return false;
          }
        }
        return true;
      })
    );
  }

  public stopWatchPosition(): void {
    if (navigator.geolocation) navigator.geolocation.clearWatch(this.locationRequestsId);
    this.positionObservable = null;
  }
}
