import { ListRecommendation } from "./list-recommendation";

export interface IProductListSugestion {
  visit_date: Date;
  owner_id: number;
  client_id: string;
  lists: ListRecommendation[];
}

export class ProductListSugestion implements IProductListSugestion {
  public visit_date: Date = new Date();
  public owner_id = 0;
  public client_id = "";
  public lists: ListRecommendation[] = [];

  constructor(data?: IProductListSugestion) {
    if (data != null) {
      this.visit_date = data.visit_date != null ? data.visit_date : new Date();
      this.owner_id = data.owner_id != null ? data.owner_id : 0;
      this.client_id = data.client_id != null ? data.client_id : "";
      this.lists = data.lists != null ? data.lists.map(x => new ListRecommendation(x)) : [];
    }
  }
}
