export class ItemClient {
  public accuracy?: number[] = [];
  public altitude?: number[] = [];
  public altitudeAccuracy?: number[] = [];
  public heading?: number[] = [];
  public latitude?: number[] = [];
  public longitude?: number[] = [];
  public speed?: number[] = [];
  public timestamp?: number[] = [];
  public client_id = "";
  public imageBase64 = "";
  public imageURL = "";
  public account_id = 0;
  public user_id = 0;
  public dataInLocalStorage?: boolean = null;

  constructor(itemClient?: ItemClient) {
    if (itemClient) {
      if (itemClient.accuracy != null) this.accuracy = itemClient.accuracy;
      if (itemClient.altitude != null) this.altitude = itemClient.altitude;
      if (itemClient.altitudeAccuracy != null) this.altitudeAccuracy = itemClient.altitudeAccuracy;
      if (itemClient.heading != null) this.heading = itemClient.heading;
      if (itemClient.latitude != null) this.latitude = itemClient.latitude;
      if (itemClient.longitude != null) this.longitude = itemClient.longitude;
      if (itemClient.speed != null) this.speed = itemClient.speed;
      if (itemClient.timestamp != null) this.timestamp = itemClient.timestamp;
      if (itemClient.client_id != null) this.client_id = itemClient.client_id;
      if (itemClient.imageBase64 != null) this.imageBase64 = itemClient.imageBase64;
      if (itemClient.imageURL != null) this.imageURL = itemClient.imageURL;
      if (itemClient.account_id != null) this.account_id = itemClient.account_id;
      if (itemClient.user_id != null) this.user_id = itemClient.user_id;
      if (itemClient.dataInLocalStorage != null) this.dataInLocalStorage = itemClient.dataInLocalStorage;
    }
  }
}
