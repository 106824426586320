import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "short_reason_justify_schedule" })
export class ShortReasonJustifySchedulePipe implements PipeTransform {
  public transform(category_id: string): string {
    let ret = "";
    switch (category_id) {
      case "do_not_schedule":
        ret = "Não agendar"; //clientes que são desconsiderados pelo vendedor para a geração de agenda
        break;
      case "do_not_schedule_if_blocked":
        ret = "Bloqueado no ERP"; //clientes que foram bloqueados pois estão marcados como bloqueados no ERP
        break;
      case "do_not_schedule_if_defaulting":
        ret = "Inadimplente"; //clientes que são desconsiderados pelo vendedor por estarem inadimplentes desde que o vendedor optou por ignorar inadimplentes
        break;
      case "rescheduling_future_appointment":
        ret = "Reagendado Prox. mês"; //clientes que foram agendados pelo vendedor para que o próximo atendimento ocorra apenas a partir do mês seguinte
        break;
      case "past_week_upper_bound":
        ret = "Cliente de Rota Fixa"; //clientes que são elegíveis apenas até uma determinada semana do mês parametrizada pelo usuário para agendas/rotas fixas e essa semana já se passou.
        break;
      case "too_early":
        ret = "Muito cedo"; // clientes que não foram agendados no mês corrente por terem a próxima data ideal de agendamento para algum mês seguinte. Logo, está "muito cedo" para agendá-lo.
        break;
      case "no_shared_working_days":
        ret = "Não há dias em comum"; // 'Clientes que não foram agendados por não terem dias em comum com os dias de trabalho do vendedor'
        break;
      case "intraportfolio_full_daily_capacity": //clientes que não foram agendados devido a capacidade de atendimento do vendedor encontrar-se totalmente preenchida.
      case "extraportfolio_full_daily_capacity": //clientes que não foram agendados devido a capacidade de atendimento de clientes fora da própria carteira do vendedor.
        ret = "Fora da capacidade";
        break;
      case "left_the_wallet":
        ret = "Excluído da Carteira"; //clientes que não foram agendados por não pertencerem mais a carteira do vendedor
        break;
      case "undefined_reason":
        ret = "Razão desconhecida"; // clientes que não foram agendados por algum motivo desconhecido
        break;
      case "core_error":
        ret = "Erro no govendas"; //Agendas não foram geradas porque ocorreu algum erro no Core
        break;
      case "save_database":
        ret = "Erro na agenda"; //Agendas foram geradas mas, por algum motivo, ocorreu um erro durante o processo de salvamento dessas agendas.
        break;
      case "regular_schedule":
        ret = "Agendado pelo govendas"; //clientes que foram agendados normalmente (informar o último atendimento, frequência, nível de preferência do dia que foi agendado)
        break;
      case "appointment_by_user":
        ret = "Agendado pelo vendedor"; // clientes que foram agendados pelo vendedor
        break;
      case "missing_validated_coordinates":
        ret = "Coordenadas inválidas"; // clientes que não foram agendados por não tem coordenadas validadas. Esta justificativa só se aplica a vendedores de campo.
        break;
      case "static_schedule_by_parameters":
        ret = "Agendamento fixo por parametrização"; // clientes que foram parametrizados para agenda fixa pelo vendedor
        break;
    }

    return ret;
  }
}

@Pipe({ name: "long_reason_justify_schedule" })
export class LongReasonJustifySchedulePipe implements PipeTransform {
  public transform(category_id: string): string {
    let ret = "";
    switch (category_id) {
      case "do_not_schedule":
        ret = "Clientes que são desconsiderados pelo vendedor para a geração de agenda";
        break;
      case "do_not_schedule_if_blocked":
        ret = "Clientes que foram bloqueados pois estão marcados como bloqueados no ERP";
        break;
      case "do_not_schedule_if_defaulting":
        ret = "Clientes que são desconsiderados pelo vendedor para a geração de agenda por estarem inadimplentes";
        break;
      case "rescheduling_future_appointment":
        ret = "Clientes que foram agendados pelo vendedor para que o próximo atendimento ocorra apenas a partir do mês seguinte";
        break;
      case "past_week_upper_bound":
        ret = "Clientes que são elegíveis apenas até uma determinada semana do mês parametrizada pelo usuário para agendas/rotas fixas e essa semana já se passou.";
        break;
      case "too_early":
        ret = 'Clientes que não foram agendados no mês corrente por terem a próxima data ideal de agendamento para algum mês seguinte. Logo, está "muito cedo" para agendá-lo';
        break;
      case "no_shared_working_days":
        ret = "Clientes que não foram agendados por não terem dias  em comum com os dias de trabalho do vendedor";
        break;
      case "intraportfolio_full_daily_capacity": //clientes que não foram agendados devido a capacidade de atendimento do vendedor encontrar-se totalmente preenchida.
      case "extraportfolio_full_daily_capacity": //clientes que não foram agendados devido a capacidade de atendimento de clientes fora da própria carteira do vendedor.
        ret = "Clientes que não foram agendados devido a capacidade de atendimento do vendedor encontrar-se totalmente preenchida";
        break;
      case "left_the_wallet":
        ret = "Clientes que não foram agendados por não pertencerem mais a carteira do vendedor";
        break;
      case "undefined_reason":
        ret = "Clientes que não foram agendados por algum motivo desconhecido";
        break;
      case "core_error":
        ret = "Agendas não foram geradas porque ocorreu algum erro no Core";
        break;
      case "save_database":
        ret = "Agendas foram geradas mas, por algum motivo, ocorreu um erro durante o processo de salvamento dessas agendas";
        break;
      case "regular_schedule":
        ret = "Clientes que foram agendados normalmente";
        break;
      case "appointment_by_user":
        ret = "Clientes que foram agendados pelo vendedor";
        break;
      case "static_schedule_by_parameters":
        ret = "Clientes que foram parametrizados para agenda fixa pelo vendedor";
        break;
    }

    return ret;
  }
}
