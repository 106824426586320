import { environment } from "./../../../../environments/environment";
import { Injectable } from "@angular/core";
import * as mixpanel from "mixpanel-browser";
import { User, UserAccount } from "app/main/shared/models";
import { isDevMode } from "@angular/core";
import { OnlineService } from "./online.service";
import { Utils } from "./utils";

@Injectable({
  providedIn: "root"
})
export class TrackingService {
  public enabled = true;

  constructor(private onlineService: OnlineService) {}

  public init(account: UserAccount, user: User): void {
    if (this.onlineService.onlineSnapshot) {
      if (isDevMode()) {
        mixpanel.opt_out_tracking();
      } else {
        mixpanel.opt_in_tracking();
      }

      mixpanel.people.set({
        ...user,
        account_name: account.name,
        govendas_version: environment.app_version,
        screen_size: `${screen.availWidth}x${screen.availHeight}`,
        isAppInstalled: Utils.isAppInstalled()
      });

      mixpanel.identify(`${user.account_id}-${user.id}`);
    }
  }

  public track(id: string, data: any = null, force: boolean = false): void {
    if ((this.onlineService.onlineSnapshot && this.enabled) || force) {
      const complementData = {
        govendas_version: environment.app_version
      };
      const mixpanelData = data ? { ...data, ...complementData } : complementData;
      mixpanel.track(id, mixpanelData);
    }
  }
}
