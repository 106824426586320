import { ItemClient } from "./itemClient";

export class SyncItem {
  public photos: ItemClient[]

  constructor(syncItem?: SyncItem) {
    if (syncItem) {
      this.photos = syncItem.photos;
    } else {
      this.photos = []
    }
  }
}

export class SyncData {
  public id?: number;
  public patternId: number;
  public url: string;
  public httpMethod: string;
  public payload: any;
  public accountId: number;
  public userId: number;
  public date: Date;
}
