import { ProductTag } from "./product-tag";
import { ProductFeedback } from "./product-feedback";

export class Recommendation {
  public client_id: string;
  public list_type: number;
  public salesman_id: string;
  public rank = 0;
  public product_id = "";
  public product_name = "";
  public unit_value = 0;
  public num_items = 0;
  public tags: ProductTag[] = [];
  public feedbacks: ProductFeedback[] = [];

  constructor(data?: Recommendation) {
    if (data != null) {
      this.client_id = data.client_id != null ? data.client_id : "";
      this.list_type = data.list_type != null ? data.list_type : null;
      this.salesman_id = data.salesman_id != null ? data.salesman_id : "";
      this.rank = data.rank != null ? data.rank : 0;
      this.product_id = data.product_id != null ? data.product_id : "";
      this.product_name = data.product_name != null ? data.product_name : "";
      this.unit_value = data.unit_value != null ? data.unit_value : 0;
      this.num_items = data.num_items != null ? data.num_items : 0;
      this.tags = data.tags != null ? data.tags : [];
      this.feedbacks = data.feedbacks != null ? data.feedbacks.map(x => new ProductFeedback(x)) : [];
    }
  }

  get numItems(): string {
    return this.num_items ? this.num_items.toString() : "-";
  }

  get isPositiveFeedback(): boolean {
    if (this.feedbacks.length === 0) return null;

    const feedback = this.feedbacks[0];

    return feedback.isPositive;
  }

  set isPositiveFeedback(value: boolean) {
    if (this.feedbacks.length === 0) {
      this.feedbacks = [new ProductFeedback()];
    }
    const feedback = this.feedbacks[0];
    feedback.isPositive = value;
  }

  get thumbsUpSelected(): boolean {
    return this.isPositiveFeedback;
  }

  get thumbsDownSelected(): boolean {
    return this.isPositiveFeedback === false;
  }

  get feedback(): ProductFeedback {
    if (!(this.feedbacks && this.feedbacks.length > 0)) {
      this.feedbacks = [new ProductFeedback()];
    }

    return this.feedbacks[0];
  }
}
