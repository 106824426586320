import { NotificationEvent } from "./../models/push-notification";
import { INotificationEvent } from "../models/push-notification";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "environments/environment";
import { TrackingService } from "./tracking.service";
import { TrackingEvents } from "../models";
import { PushNotificationsRepository } from "../repositories/push-notifications.repository";

@Injectable({
  providedIn: "platform"
})
export class PushNotificationService {
  private readonly BASE_URL = environment.BASE_URL;
  public history$ = new Subject<NotificationEvent[]>();

  constructor(private http: HttpClient, private trackingService: TrackingService, private notificationsRepository: PushNotificationsRepository) {}

  public get history(): NotificationEvent[] {
    return this.notificationsRepository.history;
  }

  public get notificationDatas(): NotificationEvent[] {
    return this.notificationsRepository.notificationsData;
  }

  public getEventData(id: number): Observable<NotificationEvent> {
    const url = `${this.BASE_URL}/events/${id}`;

    const event = this.notificationDatas.find(item => item.id === id);

    if (event) {
      return of(event);
    } else {
      return this.http.get<INotificationEvent>(url).pipe(
        map(x => new NotificationEvent(x)),
        tap(resp => {
          this.notificationsRepository.addNotificationData(resp);
        })
      );
    }
  }

  public getHistory(): Observable<NotificationEvent[]> {
    const url = `${this.BASE_URL}/events/history?limit=25`;

    return this.http.get<INotificationEvent[]>(url).pipe(
      map(x => x.map(y => new NotificationEvent(y))),
      tap(resp => {
        if (this.history.length !== 0 && this.history.length !== resp.length) {
          const newNotification = resp[0];
          this.trackingService.track(TrackingEvents.SD_EVENT_RECEIVED, {
            notificaiton_id: newNotification.id
          });
        }

        this.notificationsRepository.saveHistory(resp);
        this.history$.next(resp);
      })
    );
  }

  public markAsRead(id: number): Observable<INotificationEvent> {
    const url = `${this.BASE_URL}/events/${id}`;
    const event = this.notificationDatas.find(item => item.id === id);

    const _history = this.notificationsRepository.history;

    if (event && event.read) {
      return of(event);
    } else {
      return this.http.post<INotificationEvent>(url, {}).pipe(
        tap(resp => {
          if (_history && _history.length > 0) {
            for (const item of _history) {
              if (item.id === id) {
                item.read = true;
                this.notificationsRepository.updateOneData(item);
                break;
              }
            }
          }
          this.notificationsRepository.saveHistory(_history);
        })
      );
    }
  }
}
