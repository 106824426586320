import { ScheduledMetadata } from "./agenda";

export interface IFollowupBase {
  id: number;
  amount: number;
  unknown: number;
  visit_percent: number;
  planned: number;                       // Planejado
  not_planned: number;                   // Não planejado
  made_planned: number;                  // Planejado e registrado
  made_not_planned: number;              // Não planejado e registrado
  not_contacted: number;                 // Planejado em aberto
  contacted_planned: number;             // Planejado em andamento
  contacted_not_planned: number;         // Não planejado em andamento
  attended_planned: number;              // Planejado sem venda
  attended_not_planned: number;          // Não planejado sem venda
  positivized_planned: number;           // Planejado com venda
  positivized_not_planned: number;       // Não planejado com venda
  presential_by_distance: number;        // Atendimentos Presenciais
  without_attendance_coordinate: number; // Atendimento sem Coordenada
  without_trusted_coordinate: number;    // Atendimento sem Coordanada Confiável
}

export interface IFollowupIndicators extends IFollowupBase {
  salesman?: string;
  salesman_id: string;
  visit_date: string;
  max_visit: number;
  contactedPercent: number;
}

export class FollowupIndicators implements IFollowupIndicators {
  public amount = 0;
  public id = 0;
  public salesman = "";
  public salesman_id = "";
  public visit_date = "";
  public unknown = 0;
  public visit_percent = 0;
  public contactedPercent = 0;
  public max_visit = 0;
  
  public planned = 0;                        // Planejado
  public not_planned = 0;                    // Não planejado
  public made_planned = 0;                   // Planejado e registrado
  public made_not_planned = 0;               // Não planejado e registrado
  public not_contacted = 0;                  // Planejado em aberto
  public contacted_planned = 0;              // Planejado em andamento
  public contacted_not_planned = 0;          // Não planejado em andamento
  public attended_planned = 0;               // Planejado sem venda
  public attended_not_planned = 0;           // Não planejado sem venda
  public positivized_planned = 0;            // Planejado com venda
  public positivized_not_planned = 0;        // Não planejado com venda
  public presential_by_distance = 0;         // Atendimentos Presenciais
  public without_attendance_coordinate = 0;  // Atendimento sem Coordenada
  public without_trusted_coordinate = 0;     // Atendimento sem Coordanada Confiável

  constructor(source?: IFollowupIndicators) {
    if (source) {
      if (source.amount != null) this.amount = source.amount;
      if (source.contacted_planned != null) this.contacted_planned = source.contacted_planned;
      if (source.contacted_not_planned != null) this.contacted_not_planned = source.contacted_not_planned;
      if (source.id != null) this.id = source.id;
      if (source.not_contacted != null) this.not_contacted = source.not_contacted;
      if (source.not_planned != null) this.not_planned = source.not_planned;
      if (source.planned != null) this.planned = source.planned;
      if (source.positivized_planned != null) this.positivized_planned = source.positivized_planned;
      if (source.positivized_not_planned != null) this.positivized_not_planned = source.positivized_not_planned;
      if (source.salesman != null) this.salesman = source.salesman;
      if (source.salesman_id != null) this.salesman_id = source.salesman_id;
      if (source.unknown != null) this.unknown = source.unknown;
      if (source.visit_percent != null) this.visit_percent = source.visit_percent;
      if (source.attended_planned != null) this.attended_planned = source.attended_planned;
      if (source.attended_not_planned != null) this.attended_not_planned = source.attended_not_planned;
      if (source.made_planned != null) this.made_planned = source.made_planned;
      if (source.made_not_planned != null) this.made_not_planned = source.made_not_planned;
      if (source.visit_date != null) this.visit_date = source.visit_date;
      if (source.presential_by_distance != null) this.presential_by_distance = source.presential_by_distance;
      if (source.without_attendance_coordinate != null) this.without_attendance_coordinate = source.without_attendance_coordinate;
      if (source.without_trusted_coordinate != null) this.without_trusted_coordinate = source.without_trusted_coordinate;
      
      this.calcContactedPercent();
    }
  }

  public getContacted(): number {
    return this.made_planned;
  }

  public calcContactedPercent(): void {
    this.contactedPercent = this.max_visit ? ((this.made_planned + this.made_not_planned + this.contacted_planned + this.contacted_not_planned) / this.max_visit) * 100 : 0;
  }
}

export interface IFollowupSeller {
  aggregate: FollowupIndicators;
  start_date: string;
  end_date: string;
  id: number;
  name: string;
  sellers?: FollowupIndicators[];
  days?: FollowupIndicators[];
}
export class FollowupSeller implements IFollowupSeller {
  public aggregate = new FollowupIndicators();
  public start_date = "";
  public end_date = "";
  public id = null;
  public name = "";
  public sellers?: FollowupIndicators[] = [];
  public days?: FollowupIndicators[] = [];

  constructor(source?: IFollowupSeller) {
    if (source) {
      if (source.aggregate != null) this.aggregate = new FollowupIndicators(source.aggregate);
      if (source.start_date != null) this.start_date = source.start_date;
      if (source.end_date != null) this.end_date = source.end_date;
      if (source.id != null) this.id = source.id;
      if (source.name != null) this.name = source.name;
      if (source.sellers) {
        this.sellers = source.sellers.map(x => new FollowupIndicators(x));
      }
      if (source.days) {
        this.days = source.days.map(x => new FollowupIndicators(x));
      }
    }
  }
}

export interface IFollowupVisitItem {
  amount: number;
  block: string;
  city: string;
  client: string;
  client_id: string;
  feedback: string;
  feedback_id: number;
  feedback_date: string;
  observations?: string;
  rescheduled_date?: string;
  generated_by: number;
  house_number: string;
  neighborhood: string;
  non_schedulable: boolean;
  num_itens: number;
  planned: boolean;
  seq_number: number;
  state: string;
  thoroughfare: string;
}

export interface IFollowupClients extends IFollowupBase {
  visit_percent: number;
  visits: IFollowupVisitItem[];
}

export interface IHistoryItem {
  amount: number;
  days_without_buying: number;
  days_without_contact: number;
  feedback: string;
  feedback_date: string;
  feedback_id: number;
  num_itens: number;
  observations: string;
  salesman: string;
  salesman_id: string;
  schedule_type: number;
  scheduled_metadata: ScheduledMetadata;
  visit_date: string;
  visit_frequency: number;
  group_code: number;
  rescheduled_creation_date?: string;
}

export interface IFollowupHistory {
  client_id: string;
  last_contact: string;
  visit_frequency: number;
  history: IHistoryItem[];
}

export class FollowupHistory implements IFollowupHistory {
  public client_id = "";
  public last_contact = "";
  public visit_frequency: number = null;
  public history: IHistoryItem[] = [];

  constructor(source?: IFollowupHistory) {
    if (source) {
      if (source.client_id != null) this.client_id = source.client_id;
      if (source.last_contact != null) this.last_contact = source.last_contact;
      if (source.visit_frequency != null) this.visit_frequency = source.visit_frequency;
      if (source.history != null) this.history = source.history;
    }
  }
}
