<modal-component>
  <span title>Adiar lembrete</span>
  <div close-button>
    <button mat-icon-button (click)="onCancel()" aria-label="Fechar janela">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div content-body class="content-body">
    <form class="delay-form">
      <mat-form-field appearance="outline" style="max-width: 70px">
        <input
          matInput
          placeholder="tempo"
          min="1"
          [max]="maxInputValue"
          type="number"
          [(ngModel)]="inputValue"
          [ngModelOptions]="{ standalone: true }"
          (input)="checkInputLimit()"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="timeUnit" (selectionChange)="changeUnit($event)" [ngModelOptions]="{ standalone: true }">
          <mat-option [value]="'minutes'">Minutos</mat-option>
          <mat-option [value]="'hours'">Horas</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div content-ok>
    <button mat-stroked-button color="accent" class="mr-16" (click)="apply()">Adiar</button>
  </div>
</modal-component>
