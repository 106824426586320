import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { TrackingService } from ".";
import { MetricsData } from "../models";

@Injectable()
export class MetricsService {
  private BASE_URL;

  constructor(private http: HttpClient, private trackingService: TrackingService) {
    this.BASE_URL = environment.BASE_URL;
  }

  public sendLoggedUsers(): Observable<any> {
    const url = `${this.BASE_URL}/metrics/logged-users`;

    return this.http.post(url, null);
  }

  public finishLoading(data: MetricsData): void {
    const trackingData = data.extractData();
    if (trackingData.totalTime === 0) trackingData.totalTime = trackingData.backendTime + trackingData.responseTime + trackingData.processingTime;

    this.trackingService.track("fim do loading", trackingData);
  }
}
