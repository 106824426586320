import { Moment } from "moment";
import * as numeral from "numeral";
import "numeral/locales/pt-br";
import { ClientParams, GeoJsonGeometry } from "../models";
import { environment } from "environments/environment";

export class Utils {
  public static getDateToServer(date: Date): string {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate())
    );
  }

  public static getMomentDateToServer(date: Moment): String {
    return date.format("YYYY-MM-DD");
  }

  public static setDefaultParams(params: ClientParams): ClientParams {
    const paramsWithDefault = new ClientParams(params);
    if (!params.freq) paramsWithDefault.freq = 30;

    if (!params.mon_pref && !params.tue_pref && !params.wed_pref && !params.thu_pref && !params.fri_pref && !params.sat_pref && !params.sun_pref) {
      paramsWithDefault.mon_pref = paramsWithDefault.tue_pref = paramsWithDefault.wed_pref = paramsWithDefault.thu_pref = paramsWithDefault.fri_pref = paramsWithDefault.sat_pref = paramsWithDefault.sun_pref = 3;
    }

    if (params.non_schedulable === undefined || params.non_schedulable === null) {
      paramsWithDefault.non_schedulable = false;
    }

    return paramsWithDefault;
  }

  public static formatCurrencyNumber(value: number): string {
    numeral.locale("pt-br");
    return numeral(value).format("$ 0,0.00");
  }

  public static bytesForHuman(value: number): { value: number; unit: string } {
    const units = ["Kb", "Mb", "Gb", "Tb"];
    let unitsIndex = 0;
    if (value) {
      if (value < 1024) return { value, unit: "b" };

      let newValue = value / 1024;
      while (newValue > 1024) {
        newValue /= 1024;
        unitsIndex++;
      }

      return { value: newValue, unit: units[unitsIndex] };
    } else return null;
  }

  public static isAppInstalled(): boolean {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  public static locationStatusLabel(position: GeolocationPosition, error: Partial<GeolocationPositionError>, positionLabels: [string, string, string]): string {
    if (error) {
      const { code } = error;
      if (code === 1) return "Bloqueado pelo usuário";
      if (code === 2) return "GPS com problemas";
      if (code === 3) return "Tempo limite de captura excedido";
      if (code === -1) return "Sem suporte a GPS";
    }

    if (position && position.coords) {
      const { accuracy } = position.coords;
      if (accuracy <= environment.good_accuracy_threshold) return positionLabels[2];
      if (accuracy > environment.good_accuracy_threshold && accuracy < environment.bad_accuracy_threshold) return positionLabels[1];
      if (accuracy >= environment.bad_accuracy_threshold) return positionLabels[0];
    }

    return "Aguardando a localização...";
  }

  public static locationStatusColor(position: GeolocationPosition, error: Partial<GeolocationPositionError>): string {
    if (!(position && position.coords) && !error) return "";
    if (!!error) return "red-fg";

    const { accuracy } = position.coords;
    const goodLocation = accuracy <= environment.good_accuracy_threshold;
    const doubtfulLocation = accuracy > environment.good_accuracy_threshold && accuracy < environment.bad_accuracy_threshold;
    const badLocation = accuracy >= environment.bad_accuracy_threshold;
    if (badLocation) return "red-fg";
    else if (doubtfulLocation) return "amber-fg";
    else if (goodLocation) return "green-fg";
    else return "";
  }

  public static getAvailableVideoInputs(): Promise<MediaDeviceInfo[]> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return Promise.reject("enumerateDevices() not supported.");
    }

    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices: MediaDeviceInfo[]) => {
          resolve(devices.filter((device: MediaDeviceInfo) => device.kind === "videoinput"));
        })
        .catch(err => {
          reject(err.message || err);
        });
    });
  }

  public static isValidPoint(point: GeoJsonGeometry): boolean {
    return point && point.coordinates && point.coordinates.length === 2;
  }

  public static getDistanceFromGeomInKm(geo1: GeoJsonGeometry, geo2: GeoJsonGeometry): number {
    const [lat1, lon1] = geo1.coordinates;
    const [lat2, lon2] = geo2.coordinates;
    return this.getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
  }

  public static getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  public static generateBackgroundColorByPercent(percent: number = 0): string {
    const finalPercent = (0.5 * percent + 50) / 100;
    const style = `rgba(67, 160, 71, ${finalPercent > 1 ? 1 : finalPercent}) !important`;
    return style;
  }

  private static deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  public static snakeToCamel(str: string): string {
    return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));
  }
}
