import { RuptureParams } from "./rupture-params";
import { WorkingTime } from "./working-time";

export class MetaParameters {
  public activeFreq: number;
  public inactiveFreq: number;
  public skipDefaulting: boolean;
  public rupture_params: RuptureParams;
  public working_time: WorkingTime[];

  constructor(source?: MetaParameters) {
    if (source) {
      this.activeFreq = source.activeFreq;
      this.inactiveFreq = source.inactiveFreq;
      this.skipDefaulting = source.skipDefaulting;
      this.rupture_params = source.rupture_params;
      this.working_time = source.working_time;
    }
  }
}
