import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

import { environment } from "environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BatchCreatedUser, IUserHierarchy, User, UserEnabledResponse, UserHierarchy } from "../models";
import { SentryErrorHandler } from "./sentry-error-handler.service";

@Injectable()
export class UsersService {
  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient, private sentryService: SentryErrorHandler) {}

  private sendError(err: any, requestId?: string): void {
    if (requestId) {
      const extra = {};
      extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
      this.sentryService.handleError(err, extra);
    } else {
      this.sentryService.handleError(err);
    }
  }

  public getUsersAssociatedWhithMe(): Observable<User> {
    const url = `${this.BASE_URL}/users?token=${localStorage.getItem("token")}`;
    return this.http.get<User>(url);
  }

  public getUsersDontAssociatedWhithMe(): Observable<User[]> {
    const url = `${this.BASE_URL}/users/list?token=${localStorage.getItem("token")}`;
    return this.http.get<User[]>(url);
  }

  public getUsersAssociatedWhithID(id: number): Observable<User> {
    const url = `${this.BASE_URL}/users/${id}?token=${localStorage.getItem("token")}`;
    return this.http.get<User>(url);
  }

  public getUsersDontAssociatedWhithID(id: number): Observable<User[]> {
    const url = `${this.BASE_URL}/users/list/${id}?token=${localStorage.getItem("token")}`;
    return this.http.get<User[]>(url);
  }

  public updateUser(user: User): Observable<any> {
    const url = `${this.BASE_URL}/users/update/${user.id}?token=${localStorage.getItem("token")}`;
    const body = user;
    return this.http.put(url, body);
  }

  public updatePermissionToRuptureRule(user: User): Observable<any> {
    const url = `${this.BASE_URL}/users/update/${user.id}?token=${localStorage.getItem("token")}`;
    const body = { can_update_rupture_rule: user.can_update_rupture_rule };
    return this.http.put(url, body);
  }

  public createUser(user: User): Observable<any> {
    const url = `${this.BASE_URL}/users/create?token=${localStorage.getItem("token")}`;
    const body = user;
    return this.http.post(url, body);
  }

  public batchCreateUsers(users: User[]): Observable<BatchCreatedUser[]> {
    const url = `${this.BASE_URL}/users/create/all`;
    const body = users;
    return this.http.post<BatchCreatedUser[]>(url, body);
  }

  public disableUsers(users: User[]): Observable<UserEnabledResponse[]> {
    const url = `${this.BASE_URL}/users/update/enabled/all/false`;
    const ids = users.map(user => user.id);
    return this.http.put<any>(url, ids);
  }

  public enableUsers(users: User[]): Observable<UserEnabledResponse[]> {
    const url = `${this.BASE_URL}/users/update/enabled/all/true`;
    const ids = users.map(user => user.id);
    return this.http.put<any>(url, ids);
  }

  public disableUser(user: User): Observable<User> {
    const url = `${this.BASE_URL}/users/update/enabled/${user.id}`;
    const body = { active: false };
    return this.http.put<User>(url, body);
  }

  public enableUser(user: User): Observable<User> {
    const url = `${this.BASE_URL}/users/update/enabled/${user.id}`;
    const body = { active: true };
    return this.http.put<User>(url, body);
  }

  public associateUser(user_id: number, target_user_id: number): Observable<any> {
    const url = `${this.BASE_URL}/users/relationships/${user_id}/${target_user_id}?token=${localStorage.getItem("token")}`;
    const body = {};
    return this.http.put(url, body);
  }

  public unassociateUser(user_id: number, target_user_id: number): Observable<any> {
    const url = `${this.BASE_URL}/users/relationships/${user_id}/${target_user_id}?token=${localStorage.getItem("token")}`;
    return this.http.delete<any>(url);
  }

  public addPermissions(userIds: number[], permissions: string[]): Observable<User[]> {
    const url = `${this.BASE_URL}/users/permission`;

    const params = {
      user_ids: userIds,
      permissions
    };

    return this.http.put<User[]>(url, params).pipe(
      catchError(err => {
        const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
        this.sendError(err, requestId);
        return throwError(err);
      })
    );
  }

  public removePermissions(userIds: number[], permissions: string[]): Observable<User[]> {
    const url = `${this.BASE_URL}/users/permission`;

    const params = {
      user_ids: userIds,
      permissions
    };

    return this.http
      .request<User[]>("DELETE", url, { body: params, observe: "body" })
      .pipe(
        catchError(err => {
          const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
          this.sendError(err, requestId);
          return throwError(err);
        })
      );
  }

  public updateEvents(userIds: number[], eventChannels: string[]): Observable<User[]> {
    const url = `${this.BASE_URL}/users/events`;

    const params = {
      user_ids: userIds,
      events: eventChannels
    };

    return this.http.put<User[]>(url, params).pipe(
      catchError(err => {
        const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
        this.sendError(err, requestId);
        return throwError(err);
      })
    );
  }

  public getUsersInHerarchy(userIds: number[]): Observable<UserHierarchy[]> {
    const url = `${this.BASE_URL}/related/users`;
    const params = {
      users: userIds.map(x => `${x}`)
    };

    return this.http
      .get<IUserHierarchy[]>(url, { params })
      .pipe(map(resp => resp.map(x => new UserHierarchy(x))));
  }
}
