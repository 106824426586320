import { Component } from "@angular/core";
import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { Toast, ToastrService, ToastPackage } from "ngx-toastr";

@Component({
  selector: "[reminder-toastr]",
  templateUrl: "./reminder-toastr.component.html",
  styleUrls: ["./reminder-toastr.component.scss"],
  animations: [
    trigger("flyInOut", [
      state(
        "inactive",
        style({
          opacity: 0
        })
      ),
      transition(
        "inactive => active",
        animate(
          "400ms ease-out",
          keyframes([
            style({
              transform: "translate3d(100%, 0, 0) skewX(-30deg)",
              opacity: 0
            }),
            style({
              transform: "skewX(20deg)",
              opacity: 1
            }),
            style({
              transform: "skewX(-5deg)",
              opacity: 1
            }),
            style({
              transform: "none",
              opacity: 1
            })
          ])
        )
      ),
      transition(
        "active => removed",
        animate(
          "400ms ease-out",
          keyframes([
            style({
              opacity: 1
            }),
            style({
              transform: "translate3d(100%, 0, 0) skewX(30deg)",
              opacity: 0
            })
          ])
        )
      )
    ])
  ],
  preserveWhitespaces: false
})
export class ReminderToastrComponent extends Toast {
  public okButtonLabel: "Concluir" | "Registrar atendimento" = "Concluir";

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  public action(event: Event, source: "delay" | "action"): boolean {
    event.stopPropagation();
    this.toastPackage.triggerAction(source);
    return false;
  }
}
