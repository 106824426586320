import { Recommendation } from "./recommentation";

export class ListRecommendation {
  public list_name = "";
  public thumbsUpSelected = false;
  public thumbsDownSelected = false;
  public recommendations: Recommendation[] = [];

  constructor(data?: ListRecommendation) {
    this.list_name = data.list_name != null ? data.list_name : "";
    this.recommendations = data.recommendations != null ? data.recommendations.map(x => new Recommendation(x)) : [];
  }
}
