import { Injectable } from "@angular/core";
import { Agenda, IAgenda, IClientParams, IFeedback } from "app/main/shared/models";
import { environment } from "environments/environment";
import * as moment from "moment";

@Injectable()
export class AgendaRepository {
  private _agenda: Agenda[];
  private agendaByClientId: { [key: string]: Agenda };

  get agenda(): Agenda[] {
    return this._agenda;
  }

  constructor() {
    this._agenda = [];
    this.agendaByClientId = {};
  }

  private buildAgendaByClient(): void {
    this._agenda.forEach(item => (this.agendaByClientId[item.client_id] = item));
  }

  private updateAgendaByClient(agenda: Agenda): void {
    this.agendaByClientId[agenda.client_id] = agenda;
  }

  public save(data: IAgenda[]): void {
    const agendas = data.map(x => new Agenda(x));
    this._agenda = agendas;

    this.buildAgendaByClient();
  }

  public add(data: IAgenda): void {
    const agenda = new Agenda(data);
    this._agenda.push(agenda);

    this.updateAgendaByClient(agenda);
    this.updateCache();
  }

  public updateItem(index: number, data: Partial<IAgenda>): void {
    this._agenda[index].merge(data);

    const agendaItem = this._agenda[index];
    this.updateAgendaByClient(agendaItem);
    this.updateCache();
  }

  private findByClientId(clientId: string): Agenda {
    return this.agendaByClientId[clientId] ? this.agendaByClientId[clientId] : null;
  }

  public updateFromFeedback(data: IFeedback): Promise<void> {
    if (data) {
      const agenda = this.findByClientId(data.client_id);
      if (agenda) {
        agenda.setFeedback(data);

        this.updateAgendaByClient(agenda);
        return this.updateCache();
      }
    }
  }

  public updateFromClientParams(data: IClientParams): Promise<void> {
    if (data) {
      const agenda = this.findByClientId(data.client_id);
      if (agenda) {
        agenda.setClientParams(data);

        this.updateAgendaByClient(agenda);
        return this.updateCache();
      }
    }
  }

  private updateCache(): Promise<void> {
    if (!window.caches) return;

    return caches.keys().then(keys => {
      keys.map(key => {
        if (key.indexOf("runtime") > 0) {
          return caches.open(key).then(cache => {
            const cacheKey = `${environment.BASE_URL}/agenda/${moment().format("YYYY-MM-DD")}`;
            cache.match(cacheKey).then(response => {
              if (response) {
                const respClone = response.clone();

                //Criação do novo body
                const bodyArrayStr = [JSON.stringify(this._agenda)];
                const newBlob = new Blob(bodyArrayStr, { type: "application/json" });

                //Criação do novo response
                const respOpts = {
                  status: respClone.status,
                  statusText: respClone.statusText,
                  headers: respClone.headers
                };
                const newResp = new Response(newBlob, respOpts);

                //Atualização da cache
                cache.put(cacheKey, newResp);
              }
            });
          });
        }
      });
    });
  }
}
