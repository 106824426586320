import { Injectable } from "@angular/core";
import { ContainerItem } from "../models";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ViewService {
  private contents: ContainerItem[];
  private $contents: Subject<ContainerItem>;

  constructor() {
    this.contents = [];
    this.$contents = new Subject<ContainerItem>();
  }

  public getContents(): ContainerItem[] {
    return this.contents;
  }

  public addContent(item: ContainerItem): void {
    this.contents.push(item);
    this.$contents.next(item);
  }

  public getLastContent(): ContainerItem {
    return this.contents.slice(-1)[0];
  }

  public removeContent(): void {
    this.contents.pop();
    const last = this.contents.slice(-1)[0];
    this.$contents.next(last);
  }

  public watchContents(): Subject<ContainerItem> {
    return this.$contents;
  }
}
