import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "modal-component",
  templateUrl: "./modal-component.component.html",
  styleUrls: ["./modal-component.component.scss"]
})
export class ModalComponentComponent implements OnInit {
  @ViewChild("contentBody") public elementView: ElementRef;
  @ViewChild("modalActionsDiv", { static: false }) public modalActionsDiv: ElementRef;
  @ViewChild("modalTitleDiv", { static: false }) public modalTitleDiv: ElementRef;
  @ViewChild("modalTopFixedDiv", { static: false }) public modalTopFixedDiv: ElementRef;

  @Input()
  public modalpanelClass: string;

  @Input()
  public modalContentClass: string;

  @Input()
  public hideActionsDiv: boolean;

  private readonly hideScrollClass = "hideScroll";

  public panelClass: string[];
  public contentClass: string[];

  constructor(private _dialogRef: MatDialogRef<ModalComponentComponent>, public cdRef: ChangeDetectorRef) {
    this.panelClass = ["modal-wrapper"];
    this.contentClass = ["modal-content-class"];
  }

  public ngOnInit(): void {
    if (this.modalpanelClass) this.panelClass.push(this.modalpanelClass);
    if (this.modalContentClass) this.contentClass.push(this.modalContentClass);
  }

  public onCancel(): void {
    this._dialogRef.close();
  }
}
